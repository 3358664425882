import React from 'react'

export default function groupBy(arr, key) {
     const initialValue = {};
     return arr.reduce((acc, cval) => {
          const myAttribute = cval[key];
          acc[myAttribute] = [...(acc[myAttribute] || []), cval]
          return acc;
     }, initialValue);
}

